import React, { useState } from "react";
import { navigate } from "gatsby-link";
import { makeStyles, TextField, Button } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    "& > *": {
      marginBottom: "15px",
      width: "100%",
    },
  },
}));

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const Form = () => {
  const classes = useStyles();

  const [state, setState] = useState({});

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    console.log(setState)
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  return (
    <form
      name="contact"
      method="post"
      action="/thanks"
      className={classes.root}
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="form-name" value="contact" />
      <label hidden>
        {" "}
        <input name="bot-field" onChange={handleChange} />
      </label>

      <TextField
        type="text"
        label="お名前"
        variant="outlined"
        name="name"
        onChange={handleChange}
        required
      />

      <TextField
        type="email"
        label="メールアドレス"
        variant="outlined"
        name="email"
        onChange={handleChange}
        required
      />

      <TextField
        name="message"
        label="本文"
        multiline
        rows={4}
        variant="outlined"
        onChange={handleChange}
        required
      />

      <Button type="submit" variant="contained" size="large" color="primary">
        送信する
      </Button>
    </form>
  );
};

export default Form;