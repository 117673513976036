import * as React from "react"
import Layout from "../components/Organisms/layout"
import Inner from "../components/Organisms/inner"

import ContentsTextBlock from "../components/Molecules/contentsBlock"
import ContentsFooter from "../components/Molecules/contentsFooter"
import Kv from "~/components/Molecules/index/kv"
// import Next from "~/components/Molecules/index/next"
import WorksItems from "~/components/Molecules/works/worksItems"

import IndexJson from "../assets/data/indexPage.json"
import Seo from "../components/seo"

import { css } from "@emotion/react"
import { f } from "~/emotionStyles/function"
import Form from "../components/Molecules/form"

const imgData = [
  "/images/stage1/stage1.jpg",
  "/images/stage2/stage2.jpg",
  "/images/stage3/stage3.jpg",
  "/images/stage4/stage4.jpg",
  "/images/stage5/stage5.jpg",
  "/images/stage6/stage6.jpg",
  "/images/stage7/stage7.jpg",
]

const Index = () => {
  return (
    <Layout>
      <Seo title="TOP" />
      <Kv data={imgData} />
      <Inner>
        <div id="about">
          <ContentsTextBlock
            title={IndexJson.about.title}
            lead={IndexJson.about.lead}
          />
        </div>
        <div id="next">
          <ContentsTextBlock title={"次回公演"} lead={"coming soon..."} />
          {/* <Next
            title={IndexJson.next.title}
            subTitle={IndexJson.next.subTitle}
            description={IndexJson.next.description}
            cast={IndexJson.next.cast}
            ticketDescription={IndexJson.next.ticketDescription}
            ticketURL={IndexJson.next.ticketURL}
          /> */}
        </div>
        <div id="archive">
          <WorksItems />
        </div>
        <div id="contact">
          <div css={contentsWrap}>
            <h3 css={contentsTitle}>お問い合わせ</h3>
            <Form />
          </div>
        </div>
      </Inner>
      <ContentsFooter />
    </Layout>
  )
}

const contentsWrap = css({
  width: "100%",
  [f.pc()]: {
    paddingTop: f.vw(80),
  },
  [f.sp()]: {
    paddingTop: "50px",
  },
})

const contentsTitle = css({
  [f.pc()]: {
    fontSize: "32px",
    fontWeight: "bold",
    color: "#333",
    marginBottom: "35px",
  },
  [f.sp()]: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#333",
    marginBottom: "20px",
  },
})

export default Index
