
/* eslint-disable import/first */
import * as React from "react"
import Swiper from "react-id-swiper";
import { Autoplay, Swiper as RealSwiper } from "swiper";
RealSwiper.use([Autoplay]);
import {css} from "@emotion/react"
import {f} from "~/emotionStyles/function"

const Slider = ({data}) => {
  const params = {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    autoplay: {
      delay: 5000,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
  }
  const imgList = []
  const addImg = () => {
    for(let i = 0 ; i < data.length; i++){
      imgList.push(<div key={i}><img src={data[i]} alt='img' /></div>)
    }
  }
  addImg()
  return (
    <div css={contentsWrap}>
      <img css={img} src="/images/logo-white.png" alt="劇団おおたかの風"/>
      <Swiper {...params}>
        {imgList}
      </Swiper>
    </div>
    
  )
};

const contentsWrap = css({
  width:'100%',
  position:'relative',
  [f.pc()]: {
    height:'80%',
  },
})

const img = css({
  position:'absolute',
  top:'50%',
  left:'50%',
  transform:'translate(-50%,-50%)',
  width:'80%',
  zIndex:'20'
})

export default Slider;